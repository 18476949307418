import { createSlice } from "@reduxjs/toolkit";

const mySlice = createSlice({
  name: "auth",
  initialState: {
    isLoading: false,
    all: null,
    selected: null,
    new: null,
    updated: null,
    user: {},
  },
  reducers: {
    setAll(state, action) {
      state.all = action.payload;
    },

    setNew(state, action) {
      state.new = action.payload;
    },
    setUpdated(state, action) {
      state.updated = action.payload;
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setSelected(state, action) {
      state.selected = action.payload;
    },
    setUser(state, action) {
      state.user = action.payload;
    },
  },
});

export const myAuth = mySlice.actions;

export default mySlice.reducer;
