import { myContactus } from ".";

import { submitContactUsService } from "./services";
export const submitContactusRequest = (data: any) => {
  return async (dispatch: any) => {
    try {
      dispatch(myContactus.setIsLoading(true));
      const res = await submitContactUsService(data);
      console.log("Resp", res);
      if ([200, 201].includes(res?.status)) {
        dispatch(myContactus.setNew(res));
        dispatch(myContactus.setIsLoading(false));
        return true;
      }

      dispatch(myContactus.setIsLoading(false));
      return false;
    } catch (err) {
      console.log(err);
    }
  };
};
