import React, { useEffect } from "react";
import { useState } from "react";
import { FiUser, FiLock } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { loginAction } from "../../redux/auth/actions";
import { useNavigate } from "react-router-dom";

const LoginPage = () => {
  const [rememberMe, setRememberMe] = useState(false);
  const [credentials, setCredentials] = useState({
    email: "",
    password: "",
  });

  const auth = useSelector((state: any) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    loginAction(credentials)(dispatch);
    console.log("Login attempted with:", credentials);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setCredentials((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  useEffect(() => {
    const storageUser = localStorage.getItem("outAuthUser");
    const parsed = storageUser && JSON.parse(storageUser || "");
    console.log("USER:::::::::::", parsed);
    if (parsed?.accessToken) {
      navigate("/dashboard");
    }
  }, [dispatch, navigate, auth?.user]);

  console.log("Auth", auth);

  return (
    <div className="min-h-screen h-screen flex xitems-center justify-end bg-gradient-to-br from-violet-500 to-purple-500">
      {/* Abstract Background Shapes */}
      <div className="absolute left-0 top-0 w-3/4 h-full overflow-hidden">
        <div className="absolute transform -rotate-45 left-0 top-1/4">
          <div className="w-64 h-96 bg-pink-400/20 rounded-full blur-xl"></div>
        </div>
        <div className="absolute transform rotate-12 left-1/4 top-1/3">
          <div className="w-96 h-32 bg-orange-400/20 rounded-full blur-lg"></div>
        </div>
      </div>

      {/* Main Container */}
      <div className="flex xw-full w-1/2 h-full bg-white shadow-xl overflow-hidden">
        {/* Left Side - Welcome Message */}
        <div className="hidden md:flex md:w-1/2 p-12 flex-col justify-center relative">
          <h1 className="text-4xl font-bold mb-4 text-gray-800">
            Welcome to OutApp
          </h1>
          <p className="text-gray-600">
            Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam
            nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat
            volutpat.
          </p>
        </div>

        {/* Right Side - Login Form */}
        <div className="w-full md:w-1/2 bg-white p-8 md:p-12 flex flex-col justify-center">
          {/* <h2 className="text-2xl font-semibold text-gray-700 mb-8">
            USER LOGIN
          </h2> */}

          <form onSubmit={handleSubmit} className="space-y-6">
            <div className="relative">
              <FiUser className="absolute left-3 top-3 h-5 w-5 text-gray-400" />
              <input
                type="text"
                name="email"
                placeholder="Email"
                className="w-full pl-12 pr-4 py-3 bg-gray-50 rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500"
                value={credentials.email}
                onChange={handleChange}
              />
            </div>

            <div className="relative">
              <FiLock className="absolute left-3 top-3 h-5 w-5 text-gray-400" />
              <input
                type="password"
                name="password"
                placeholder="Password"
                className="w-full pl-12 pr-4 py-3 bg-gray-50 rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500"
                value={credentials.password}
                onChange={handleChange}
              />
            </div>

            <div className="flex items-center justify-between text-sm">
              <label className="flex items-center space-x-2 cursor-pointer">
                <input
                  type="checkbox"
                  checked={rememberMe}
                  onChange={(e) => setRememberMe(e.target.checked)}
                  className="rounded text-purple-500 focus:ring-purple-500"
                />
                <span className="text-gray-600">Remember</span>
              </label>
              <a href="#" className="text-gray-600 hover:text-purple-500">
                Forgot password?
              </a>
            </div>

            <button
              type="submit"
              className="w-full py-3 bg-gradient-to-r from-[#3C50E0] to-[#80CAEE] text-white rounded-lg 
                       hover:from-purple-600 hover:to-pink-600 transition-colors duration-300"
            >
              LOGIN
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
