import React, { useEffect } from "react";
import CardDataStats from "../../components/CardDataStats";
import { MdOutlineManageAccounts } from "react-icons/md";
import { MdOutlineEventAvailable } from "react-icons/md";
import { MdOutlineEventBusy } from "react-icons/md";
import { FiUsers } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { getMinimalMetrics } from "../../redux/dashboard/actions";

const ECommerce: React.FC = () => {
  const dashboard = useSelector((state: any) => state.dashboard);
  const dispatch = useDispatch();

  useEffect(() => {
    getMinimalMetrics("")(dispatch);
  }, [dispatch]);

  return (
    <>
      <div className="grid grid-cols-1 gap-4 md:grid-cols-2 md:gap-6 xl:grid-cols-4 2xl:gap-7.5">
        <CardDataStats
          title="Total accounts"
          total={dashboard?.minimalMetrics?.allUsers}
          rate="0.43%"
          levelUp
        >
          <MdOutlineManageAccounts className="w-7 h-7 text-blue-400" />
        </CardDataStats>
        <CardDataStats
          title="Total Events"
          total={dashboard?.minimalMetrics?.totalEvents}
          rate="4.35%"
          levelUp
        >
          <MdOutlineEventBusy className="w-7 h-7 text-blue-400" />
        </CardDataStats>
        <CardDataStats
          title="Upcoming Events"
          total={dashboard?.minimalMetrics?.upcomingEvents}
          rate="2.59%"
          levelUp
        >
          <MdOutlineEventAvailable className="w-7 h-7 text-blue-400" />
        </CardDataStats>
        <CardDataStats
          title="Total Users"
          total={dashboard?.minimalMetrics?.allVerifiedUsers}
          rate="0.95%"
          levelDown
        >
          <FiUsers className="w-7 h-7 text-blue-400" />
        </CardDataStats>
      </div>

      {/* <div className="mt-4 grid grid-cols-12 gap-4 md:mt-6 md:gap-6 2xl:mt-7.5 2xl:gap-7.5">
        <ChartOne />
        <ChartTwo />
        <ChartThree />
        <MapOne />
        <div className="col-span-12 xl:col-span-8">
          <TableOne />
        </div>
        <ChatCard />
      </div> */}
    </>
  );
};

export default ECommerce;
