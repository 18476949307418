import { Route, Routes } from "react-router-dom";
import EventPage from "../pages/EventPage";
import { HomePage } from "../pages/HomePage";
import LoginPage from "../pages/Auth/Login";
import DefaultLayout from "../layout/DefaultLayout";
import ECommerce from "../pages/Dashboard/ECommerce";
import CartPage from "../pages/CartPage";

const MainRouter = () => {
  return (
    <Routes>
      {/* Public routes without DefaultLayout */}
      <Route path="/" element={<HomePage />} />
      <Route path="/event/:id" element={<EventPage />} />
      <Route path="/cart/:id" element={<CartPage />} />
      <Route path="/login" element={<LoginPage />} />

      {/* Dashboard routes with DefaultLayout */}
      <Route
        path="/dashboard/*"
        element={
          <DefaultLayout isDashboard>
            <Routes>
              <Route path="" element={<ECommerce />} />
              {/* Add other dashboard routes here */}
              {/* For example: */}
              {/* <Route path="users" element={<Users />} /> */}
              {/* <Route path="settings" element={<Settings />} /> */}
            </Routes>
          </DefaultLayout>
        }
      />

      {/* <Route path="*" element={<PageNotFound />} /> */}
    </Routes>
  );
};

export default MainRouter;
