import { FaTwitter } from "react-icons/fa";
import { AiFillTikTok } from "react-icons/ai";
import { FaLinkedin } from "react-icons/fa";

const Footer = () => {
  return (
    <footer className="bg-teal-900 text-white py-1 mt-10">
      <div className="container mx-auto px-4 max-w-[80rem]">
        {/* App Download and Social Section */}
        <div className="flex flex-col items-center mt-3 md:mt-0 flex-1 md:pl-10">
          <h3 className="text-lg font-bold mb-4">DOWNLOAD OUR APP</h3>
          <div className="flex xl:items-center justify-center xl:flex-row space-x-2 xl:space-y-0">
            {/* App Store Buttons */}
            <a
              href="https://apps.apple.com/rw/app/out-app/id6482999195"
              className="inline-block"
            >
              <img
                src="https://upload.wikimedia.org/wikipedia/commons/3/3c/Download_on_the_App_Store_Badge.svg"
                alt="App Store"
                className="w-40"
              />
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=com.outgroupinc.outapp&hl=en"
              className="inline-block"
            >
              <img
                src="https://upload.wikimedia.org/wikipedia/commons/7/78/Google_Play_Store_badge_EN.svg"
                alt="Google Play"
                className="w-40"
              />
            </a>
          </div>
          {/* Social Icons */}
          {/* <p className="mt-6 mb-2">OutApp</p> */}
          <div className="flex space-x-4 my-4 text-xl">
            <a title="Twitter" href="?#" className="hover:text-gray-400">
              <FaTwitter />
            </a>
            <a title="TikTok" href="?#" className="hover:text-gray-400">
              <AiFillTikTok />
            </a>
            <a title="LinkedIn" href="?#" className="hover:text-gray-400">
              <FaLinkedin />
            </a>
          </div>
        </div>

        {/* Footer Bottom Section */}
        <div className="mb-4 text-center">
          <p className="text-sm">© OutApp2025</p>
          {/* <a
            href="?#"
            title="OutApp"
            className="inline-block mt-4 text-white p-2 bg-teal-700 rounded-full hover:bg-teal-600"
          >
            <i className="fas fa-arrow-up"></i>
          </a> */}
        </div>
      </div>
    </footer>
  );
};

export default Footer;
