import { configureStore } from "@reduxjs/toolkit";
import ContactUs from "./contactus";
import EventsReducer from "./events";
import DashboardReducer from "./dashboard";
import AuthReducer from "./auth";

const store = configureStore({
  reducer: {
    // auth: authReducer,
    events: EventsReducer,
    contactus: ContactUs,
    dashboard: DashboardReducer,
    auth: AuthReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export default store;
