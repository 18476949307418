import React from "react";

interface TicketProps {
  eventName: string;
  ticketId: string;
  ticketPrice: string;
  qrCodeUrl: string;
}

const TicketBig: React.FC<TicketProps> = ({ eventName, ticketPrice }) => {
  return (
    <div className="flex items-center my-1">
      {/* Left side of the ticket with inward curve */}

      <div
        className="relative flex items-center h-32 bg-[#043042] rounded-md text-white font-bold text-lg px-18 py-6 
        before:content-[''] before:absolute before:-left-13 before:top-1/2 
        before:w-20 before:h-20 before:bg-white before:rounded-full before:-translate-y-1/2 
        after:content-[''] after:absolute after:-right-13 after:top-1/2 
        after:w-20 after:h-20 after:bg-[#61A5C2] after:rounded-full after:-translate-y-1/2 shadow-lg"
      >
        {eventName}
      </div>

      {/* Right side of the ticket with inward curve */}
      <div
        className="relative flex h-25 bg-[#61A5C2] text-white font-semibold text-lg items-center px-8 xpy-6 
        rounded-r-lg shadow-lg after:content-[''] after:absolute after:-left-6 after:top-1/2 
        after:w-12 after:h-12 after:bg-[#61A5C2] after:rounded-full after:-translate-y-1/2"
      >
        {`$${ticketPrice}`}
      </div>
    </div>
  );
};

export default TicketBig;
