import axios from "axios";

const axiosInstance = axios.create();

axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const status = error.response?.status;
    const data = error?.response?.data;
    if (status === 401) {
      try {
        await new Promise((resolve) => setTimeout(resolve, 8000));
        localStorage.clear();
        window.location.href = "/login";
      } catch (navError) {
        console.error("Navigation to login failed:", navError);
      }
    } else if (status === 500) {
      console.log("Internal server error occured", data);
    }

    return Promise.reject(error);
  }
);

class HttpRequest {
  static patch(arg0: string, token: string) {
    throw new Error("Method not implemented.");
  }
  static async get(url: string, token?: string, p0?: { responseType: string }) {
    try {
      const res = await axiosInstance({
        method: "GET",
        url,
        headers: {
          authToken: `${token}`,
        },
      });
      return res.data;
    } catch (err: any) {
      return err;
    }
  }

  static async post(url: string, data: any, token?: string) {
    try {
      const res = await axiosInstance({
        method: "POST",
        url,
        headers: {
          authToken: `${token}`,
        },
        data,
      });

      return res.data;
    } catch (err: any) {
      console.log(err);
      return err;
    }
  }

  static async delete(url: string, token: string, data?: any) {
    try {
      const res = await axiosInstance({
        method: "DELETE",
        url,
        headers: {
          authToken: `${token}`,
        },
        data,
      });

      return res.data;
    } catch (err: any) {
      console.log(err.response);
      return err;
    }
  }

  static async update(url: string, data: any, token: string) {
    try {
      const res = await axiosInstance({
        method: "PATCH",
        url,
        headers: {
          authToken: `${token}`,
        },
        data,
      });

      return res.data;
    } catch (err: any) {
      return err;
    }
  }

  static async upload(url: string, data: any, token: string) {
    try {
      const res = await axiosInstance({
        method: "POST",
        url,
        data,
        headers: {
          "content-type": "multipart/form-data",
          authToken: `${token}`,
        },
      });

      return res.data;
    } catch (err: any) {
      return err;
    }
  }
}

export default HttpRequest;
