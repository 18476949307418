// App.js
import { useEffect, useState } from "react";
import OrganizerImage from "../assets/organizer.png";
import Ticket from "../components/Ticket";
import Footer from "../components/Footer";
import { useParams, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllEventTicketsAction,
  getOneEventAction,
} from "../redux/events/actions";
import logo from "../assets/logo.png";
import { SlCalender } from "react-icons/sl";
import { GoClock } from "react-icons/go";
import { IoLocationOutline, IoShareSocial } from "react-icons/io5";
import { BookATicketForm } from "../components/forms/BookATicketForm";
import SuccessRSVP from "../components/SuccessRSVP";
import { myEvents } from "../redux/events";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

const handleDownload = () => {
  const userAgent = navigator.userAgent || navigator.vendor;

  if (/android/i.test(userAgent)) {
    // Redirect to Google Play Store
    window.location.href =
      "https://play.google.com/store/apps/details?id=com.outgroupinc.outapp&hl=en";
  } else if (/iPad|iPhone|iPod/.test(userAgent)) {
    // Redirect to Apple App Store
    window.location.href = "https://apps.apple.com/rw/app/out-app/id6482999195";
  } else {
    // Fallback for other devices or unsupported platforms
    alert("This app is only available for Android and iOS devices.");
  }
};

const EventPage = () => {
  const eventId: string = useParams()?.id || "";
  const events = useSelector((state: any) => state.events);
  const [isSuccessRSVP, setIsSuccessRSVP] = useState(false);
  const [selectedTicket, setSelectedTicket] = useState<string | null>(null);
  const dispatch = useDispatch();

  const [isSticky, setIsSticky] = useState(false);
  const [ticketCount, setTicketCount] = useState(1);
  const [searchParams, setSearchParams] = useSearchParams();

  const payment = searchParams.get("payment"); // "success"
  const qrcode = searchParams.get("qrcode"); // "true"
  const ticketId = searchParams.get("ticketId");

  const removeQueryParams = () => {
    setSearchParams({});
  };

  const incrementTicket = () => {
    setTicketCount((prev) => prev + 1);
  };

  const decrementTicket = () => {
    setTicketCount((prev) => (prev > 1 ? prev - 1 : 1));
  };

  useEffect(() => {
    getOneEventAction(eventId)(dispatch);
    getAllEventTicketsAction(eventId)(dispatch);
  }, [dispatch, eventId]);

  useEffect(() => {
    const handleScroll = () => {
      const backdrop = document.querySelector(".backdrop-container");
      if (backdrop) {
        const { top } = backdrop.getBoundingClientRect();
        // setIsSticky(top <= 0);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleRedirect = () => {
    const redirectLink = `outapp://?share=true&event=${encodeURIComponent(
      JSON.stringify(events.selected)
    )}`;
    console.log(" Redirecting....please wait", events.selected, redirectLink);

    window.location.href = redirectLink;
  };

  const detectDevice = () => {
    const userAgent = navigator.userAgent.toLowerCase();

    if (/android/.test(userAgent)) {
      return "Android";
    }
    if (/iphone|ipad|ipod/.test(userAgent)) {
      return "iOS";
    }
    return "Browser";
  };

  useEffect(() => {
    const device = detectDevice();
    console.log("DEVICE", device, events.selected);

    if (device === "Android" || device === "iOS" /*|| device === "Browser"*/) {
      // Add a 2-second delay before calling handleRedirect
      const delayRedirect = setTimeout(() => {
        handleRedirect();
      }, 2000);

      // Cleanup the timeout to avoid memory leaks
      return () => clearTimeout(delayRedirect);
    }
  }, [events.selected]);
  const googleMapsUrl = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
    events?.selected?.location?.address
  )}`;

  console.log("Is sticky", isSticky, payment, ticketId, qrcode);

  return (
    <div
      className={`xh-screen bg-fixed bg-cover bg-center bg-opacity-40`}
      style={{
        backgroundImage: `url('${
          isSticky && events?.selected?.backdropImage
        }')`,
      }}
    >
      <header className="p-4 sticky top-0 z-10 xbg-white bg-white/30 backdrop-blur-lg">
        <div className="container flex justify-between items-center  mx-auto">
          <img src={logo} className="h-10 md:h-15" alt="logo" />
          <nav className="flex gap-6 text-black ">
            <a
              href="#"
              className="hover:text-gray-800 font-medium text-[#209bc5]"
              onClick={(e) => {
                e.preventDefault();
                handleDownload();
              }}
            >
              Download the App
            </a>
          </nav>
        </div>
      </header>
      <div
        className={`container mx-auto transition-colors duration-500 ${
          isSticky ? "bg-transparent" : "bg-[#fffdfd]"
        } min-h-screen`}
      >
        {/* Header */}

        {/* Main Content */}
        <main className="p-1 xl:p-8">
          <div
            className={`transition-colors duration-500 ${
              isSticky ? "bg-transparent" : "xbg-white"
            } overflow-hidden`}
          >
            <div
              className={`backdrop-container sticky top-0 xrelative ${
                isSticky ? "xsticky ctop-0 cz-10" : ""
              } rounded-xl overflow-hidden xshadow-lg  mx-auto xborder-2`}
            >
              {/* Background Image */}
              <div
                className={`top-0 sticky w-full h-[556px] bg-cover bg-center transition-opacity duration-300 ${
                  isSticky ? "opacity-0" : "opacity-100"
                }`}
                style={{
                  backgroundImage: `url('${
                    !isSticky && events?.selected?.backdropImage
                  }')`,
                }}
              >
                {/* Dark overlay */}
                {!isSticky && (
                  <div className="absolute inset-0 bg-black bg-opacity-40"></div>
                )}
              </div>

              {/* Text Content */}
              <div className="absolute bottom-5 left-5 text-white">
                <h1 className="text-[48px] font-bold text-white max-w-[748px]">
                  {events?.selected?.title}
                </h1>
                {/* <p className="mt-2 text-lg">Worship night, Vancouver, BC</p> */}
              </div>
              <button
                title="share the event"
                className="absolute top-4 right-4 text-white p-2 rounded-full"
              >
                <IoShareSocial className="h-7 w-7" />
              </button>
            </div>

            <hr className="hidden xl:block" />

            <div className="grid xl:grid-cols-2 gap-y-5 xl:gap-y-0 mt-16">
              <div className="flex bg-white justify-center items-center shadow-xl rounded-xl gap-x-5 xl:w-fit border-r border-gray-200 p-4">
                {/* Profile Image */}

                <img
                  src={events?.selected?.host?.profile || OrganizerImage}
                  alt="Organizer"
                  className="w-32 h-32 rounded-full object-cover mb-4"
                />
                <div className="text-[#013a4f]">
                  {/* Organizer Details */}
                  <h1 className="text-2xl font-bold mb-2 ">
                    {events?.selected?.host?.fullNames}
                  </h1>
                  <p>{events?.selected?.host?.email}</p>
                </div>
              </div>

              <div className="bg-[#e7f2f8] border-2 border-[#91C5DF] rounded-lg p-5 w-full xl:max-w-[478px]">
                {/* <h1 className="text-[48px] font-bold text-black mb-4 max-w-[748px]">
                  {events?.selected?.title}
                </h1> */}
                <div className={`flex justify-between text-black`}>
                  <div className="space-y-2">
                    <div className="flex items-center gap-x-5">
                      <SlCalender />
                      <p>
                        {new Date(events?.selected?.time).toLocaleDateString(
                          "en-US",
                          {
                            weekday: "short",
                            month: "long",
                            day: "numeric",
                            year: "numeric",
                          }
                        )}
                      </p>
                    </div>
                    <div className="flex items-center gap-x-5">
                      <GoClock />
                      <p className="uppercase">
                        {new Date(events?.selected?.time).toLocaleTimeString(
                          [],
                          {
                            hour: "2-digit",
                            minute: "2-digit",
                            hour12: true,
                          }
                        )}
                      </p>
                    </div>
                    <div className="flex items-center gap-x-5">
                      <IoLocationOutline />
                      <a
                        href={googleMapsUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-blue-400"
                      >
                        <p>{events?.selected?.location?.address}</p>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="grid xl:grid-cols-2 items-start justify-between mt-20 py-6">
              {/* Organizer Profile Section */}
              <div className="bg-white rounded-xl p-6 h-full xl:w-[calc(100%-100px)] border border-black">
                <div className="pb-7">
                  <h2 className="text-2xl text-[#013A4F] font-bold mb-6">
                    Additional Information
                  </h2>
                  <p className="text-gray-400">
                    {events?.selected?.description ||
                      "No additional infomation provided"}
                  </p>
                </div>
              </div>

              {/* Checkout Form Section */}
              <div className="flex bg-white rounded-xl flex-col items-start w-full xmax-w-screen-sm">
                {events.selected?.isPaid && payment !== "success" && (
                  <>
                    {/* Display tickets as slider on small screens */}
                    <div className="md:hidden block mx-auto mb-20 w-[calc(65vw)]">
                      <Swiper
                        spaceBetween={50}
                        slidesPerView={1}
                        onSlideChange={() => console.log("slide change")}
                        onSwiper={(swiper: any) => console.log(swiper)}
                        className="!py-8 w-full"
                        navigation
                        pagination={{ clickable: true }}
                        modules={[Navigation, Pagination]}
                      >
                        {events?.tickets.map((ticket: any) => (
                          <SwiperSlide className="">
                            <div
                              className={`flex items-center flex-col gap-y-5 xmax-w-64 p-4 border rounded-md cursor-pointer transition-shadow hover:shadow-lg ${
                                selectedTicket === ticket?._id
                                  ? "border-blue-500"
                                  : "border-gray-300"
                              }`}
                              onClick={() => {
                                setSelectedTicket(ticket?._id);
                                dispatch(myEvents.setSelectedTicket(ticket));
                              }}
                            >
                              <Ticket
                                isFree={ticket.price === 0}
                                price={ticket.price}
                                name={ticket.name}
                              />
                              <p className="text-center">
                                {ticket?.description}
                              </p>
                            </div>
                          </SwiperSlide>
                        ))}
                      </Swiper>
                    </div>

                    <div className="hidden md:block mb-5 w-full mt-10">
                      <h2 className="text-md font-bold mb-4 text-[#013A4F]">
                        Available ticket
                      </h2>
                      <div className="flex flex-col lg:flex-row gap-10 mb-5">
                        {events?.tickets.map((ticket: any) => (
                          <div
                            key={ticket.name}
                            className={`flex items-center flex-col gap-y-5 max-w-64 p-4 border rounded-md cursor-pointer transition-shadow hover:shadow-lg ${
                              selectedTicket === ticket?._id
                                ? "border-blue-500"
                                : "border-gray-300"
                            }`}
                            onClick={() => {
                              setSelectedTicket(ticket?._id);
                              dispatch(myEvents.setSelectedTicket(ticket));
                            }}
                          >
                            <Ticket
                              isFree={ticket.price === 0}
                              price={ticket.price}
                              name={ticket.name}
                            />
                            <p className="text-center">{ticket?.description}</p>
                          </div>
                        ))}
                      </div>
                    </div>
                  </>
                )}

                <div className="pr-10">
                  {!events?.rsvpEvent?.event && payment !== "success" && (
                    <BookATicketForm
                      onFinish={() => setIsSuccessRSVP(true)}
                      ticketId={selectedTicket}
                    />
                  )}
                  {(events?.rsvpEvent?.event || payment === "success") && (
                    <SuccessRSVP
                      isPaid={events?.selected?.isPaid || false}
                      onDismiss={() => {
                        setIsSuccessRSVP(false);
                        dispatch(myEvents.setRsvpEvent(null));
                        removeQueryParams();
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
      <Footer />
    </div>
  );
};

export default EventPage;
